<template>
  <div>
    <CCard>
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title">
          <span class="ul_text">SUB </span>USERS
        </h4>
      </CRow>
      <CCardBody>
        <CRow class="mb-4">
          <CCol class="col-md-3 col-12">
            <CInput
              placeholder="Username"
              autocomplete="username"
              v-model="input.username"
              :state="validateName"
              maxlength="50"
            >
              <template #prepend-content><CIcon name="cil-user" /></template>
            </CInput>
          </CCol>
          <CCol class="col-md-3 col-12">
            <CInput
              placeholder="Sub User Email"
              autocomplete="email"
              v-model="input.subUserEmail"
              type="email"
              maxlength="50"
            >
              <template #prepend-content>
                <svg width="20" height="20" viewBox="0 0 24 24" xmlns="">
                <g id="Complete"><g id="mail"><g><polyline fill="none" points="4 8.2 12 14.1 20 8.2" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <rect fill="none" height="14" rx="2" ry="2" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" width="18" x="3" y="6.5"/>
                </g></g></g></svg></template>
            </CInput>
          </CCol>
          <CCol class="col-md-3 col-12">
            <CSelect
            required
            :value.sync="input.role"
            :options="usertype_opt"
            >
            </CSelect>
          </CCol>
          <CCol class="col-md-3 col-12">
            <CInput
              placeholder="Password"
              type="password"
              autocomplete="new-password"
              v-model="input.password"
              maxlength="50"
              id="password"
            >
              <template #prepend-content
                >
                  <span @click="togglePassword('password')" style="width: 1rem;">
                    <svg v-show="showPassword.password == true" viewBox="0 0 24 24" fill="none" xmlns=""><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <svg v-show="showPassword.password == false" viewBox="0 0 24 24" fill="none" xmlns=""><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                  </span>
                </template>
            </CInput>
          </CCol>
          <!-- <CCol class="col-md-8 col-12" v-show="input.role == 'csp'">
            <multiselect
                class=""
                v-model="allowedCampigns"
                placeholder="Select Campaign"
                label="name"
                track-by="_id"
                :options="all_camp_list"
                :multiple="true"
                :taggable="true"
                :preselect-first="true"
                :close-on-select="false"
              >
              </multiselect>
          </CCol> -->
          <CCol class="col-md-12 col-12">
            <CButton class="" color="success" block @click="signup"
              >Create User</CButton
            >
          </CCol>
        </CRow>

        <div class="card-header-actions">
          <small class="text-muted">
            <multiselect
              size="sm"
              :limit="2"
              :clear-on-select="false"
              :close-on-select="true"
              :multiple="false"
              :options="perPage"
              :preselect-first="false"
              :preserve-search="false"
              @input="checkPage()"
              placeholder="PerPage"
              v-model="tableperpage"
            ></multiselect
          ></small>
        </div>
        <!-- Main table element -->
        <CDataTable
          :items="items"
          :fields="fields"
          :dark="false"
          :items-per-page="tableperpage"
          pagination
          sorter
          hover
          striped
          border
          small
          fixed
          table-filter
          light
        >
          <template #name="{ item }">
            <td v-if="item.role != 'manager'">{{ item.username }}</td>
          </template>
          <template #allowedCampigns="{ item }">
            <td>
              <span class="res_text" v-if="item.allowedCampigns.length > 0" >
                {{ item.allowedCampigns.length }}
              </span>
              <span v-else>0</span>
            </td>
          </template>
          <template #bProjection="{ item }">
            <td>
              <span class="res_text" v-if="item.bProjection.length > 0" >
                <span v-for="data in item.bProjection">
                  <CBadge v-if="projectionopt.filter(t=>t.value == data).length>0" shape="pill" color="danger" class="ml-1" style="font-size: 12px;">
                    {{ projectionopt.filter(t=>t.value == data)[0].text }}
                  </CBadge>
                </span>
              </span>
              <span v-else>All whitelisted</span>
            </td>
          </template>
          <template #bMetrics="{ item }">
            <td>
              <span class="res_text" v-if="item.bMetrics.length > 0" >
                <span v-for="data in item.bMetrics">
                  <CBadge v-if="metricsopt.filter(t=>t.value == data).length>0" shape="pill" color="danger" class="ml-1" style="font-size: 12px;">
                    {{ metricsopt.filter(t=>t.value == data)[0].text }}
                  </CBadge>
                </span>
              </span>
              <span v-else>All whitelisted</span>
            </td>
          </template>

          <template #action="{ item }">
            <td v-if="item.role != 'manager'">
              <span @click="openModel(item)">
              <CImg src="img/logo/edit.png" class="img-fluid" title="Change Password"
                                style="cursor: pointer;"
                                ></CImg>
              </span>
              <span @click="update(item)">
                <CImg
                  v-if="item.isactive == true"
                  src="img/logo/push.png"
                  class="img-fluid ml-1"
                  title="Inactive"
                  :pressed.sync="item.status"
                  style="cursor: pointer"
                ></CImg>
                <CImg
                  v-if="item.isactive == false"
                  src="img/logo/play.png"
                  class="img-fluid ml-1"
                  title="Active"
                  :pressed.sync="item.status"
                  style="cursor: pointer"
                ></CImg>
              </span>
              <span v-if="item.isactive == true"> Deactivate</span>
              <span v-if="item.isactive == false"> Activate</span>
            </td>
          </template>
        </CDataTable>
      </CCardBody></CCard
    >

    <!-- Show Model -->
    <CModal
      title="Edit User"
      :show.sync="show_model"
      size="xl"
      :centered="true"
      scrollable
      color="dark"
    >
      <CCard class="mb-0" style="min-height: 60vh !important;">
        <CCardBody class="p-4">
          <CForm>
            <CRow class="col-md-12">
              <CCol class="col-12 col-md-6">
                <CInput
                  class="col-md-12 col-0"
                  placeholder="Username"
                  autocomplete="username"
                  v-model="input1.username"
                  label="Username"
                  disabled
                >
                <template #prepend-content><CIcon name="cil-user" /></template>
              </CInput>
              </CCol>
              <CCol class="col-12 col-md-6">
                  <CInputCheckbox class="col-md-12" :checked.sync="change_password" label="Change Passowrd?" value="true"></CInputCheckbox>
                  <CInput v-if="change_password == true"
                    class="col-md-12 mt-2"
                    placeholder="Password"
                    type="password"
                    autocomplete="new-password"
                    v-model="input1.password"
                    maxlength="100"
                    id="password1"
                  >
                  <template #prepend-content
                >
                <span @click="togglePassword('password1')" style="width: 1rem;">
                    <svg v-show="showPassword.password1 == true" viewBox="0 0 24 24" fill="none" xmlns=""><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                    <svg v-show="showPassword.password1 == false" viewBox="0 0 24 24" fill="none" xmlns=""><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                </span>
                </template>
                  </CInput>
              </CCol>
            </CRow>
          
            <CRow class="col-md-12"  v-if="input1.role == 'csp'">
              <CCol>
                <label class="col-md-12">Campaigns</label>
                <multiselect
                  class="col-md-12"
                  v-model="allowedCampigns"
                  placeholder="Select Campaign"
                  label="name"
                  track-by="_id"
                  :options="camp_list"
                  :multiple="true"
                  :taggable="true"
                  :preselect-first="false"
                  :close-on-select="false"
                >
                <template slot="tag" slot-scope="{ option, remove }">
                  <span class="multiselect__tag">
                    <span>
                      {{ option.name }}
                    </span>
                    <span class="custom__remove" style="cursor: pointer;" @click="addTagBack(option)">❌</span>
                  </span>
                </template>
                </multiselect>
              </CCol>
            </CRow>
            <CRow class="col-md-12 mt-2">
              <CCol>
                <label class="col-md-12">Blacklisted <b>Group By</b></label>
                <multiselect
                  class="col-md-12"
                  v-model="bProjection"
                  placeholder="Select Matrix"
                  label="text"
                  track-by="value"
                  :options="projectionopt"
                  :multiple="true"
                  :taggable="true"
                  :preselect-first="false"
                  :close-on-select="false"
                >
                </multiselect>
              </CCol>
            </CRow>
            <CRow class="col-md-12 mt-2">
              <CCol>
                <label class="col-md-12">Blacklisted <b>Dimentions</b></label>
                <multiselect
                  class="col-md-12"
                  v-model="bMatrics"
                  placeholder="Select Dimentions"
                  track-by="value"
                  label="text"
                  :options="metricsopt"
                  :multiple="true"
                  :taggable="true"
                  :preselect-first="false"
                  :close-on-select="false"
                >
                </multiselect>
              </CCol>
            </CRow>
            <div class="col-md-12">
            <CRow class="col-md-12 mt-2" v-if="input1.role == 'csp'">
              <CCol>
                <CRow class="col-md-12 text-center">
                <CCol class="col-md-2 col-12 m-1 p-2" style="background-color: #e6e6e6;">
                  <label class="col-md-12">Live Dashboard</label>
                  <span class="col-md-12 text-left">
                    <CSwitch color="success" labelOn="ON" labelOff="OFF" :checked="isLiveDashboard" @update:checked="isLiveDashboard =!isLiveDashboard"></CSwitch>
                  </span>
                </CCol>
                <CCol class="col-md-2 col-12 m-1 p-2" style="background-color: #e6e6e6;">
                  <label class="col-md-12">View Logs</label>
                  <span class="col-md-12 text-left">
                    <CSwitch color="success" labelOn="ON" labelOff="OFF" :checked="isViewLog" @update:checked="isViewLog=!isViewLog"></CSwitch>
                  </span>
                </CCol>
                <CCol class="col-md-2 col-12 m-1 p-2" style="background-color: #e6e6e6;">
                  <label class="col-md-12">Inspector</label>
                  <span class="col-md-12 text-left">
                    <CSwitch color="success" labelOn="ON" labelOff="OFF" :checked="isInspector" @update:checked="isInspector=!isInspector"></CSwitch>
                  </span>
                </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
          </CForm>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton color="success" block @click="Edit()">Update</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import SignUpService from "./../../services/signupControlService";
import DashboardController from "./../../services/dashboardController";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import HelperFunction from "./../../helper/helperFunction";
import SettingService from "./../../services/settingControlService";
import { CCol, CInputCheckbox } from "@coreui/vue";
export default {
  components: { Multiselect },
  data() {
    return {
      change_password:false,
      bMatrics: null,
      bProjection:null,
      isLiveDashboard: false,
      isViewLog: false,
      isInspector: false,
      email: "",
      input: {username:'',role:'subUser',allowedCampigns:[],password:'',subUserEmail:''},
      input1: {password:''},
      show_model: false,
      tableperpage: 20,
      perPage: [20, 50, 100],
      items: [],
      fields: [
        { key: "email", label: "Email" },
        { key: "username", label: "Username" },
        { key: "role", label: "Role" },
        { key: "allowedCampigns", label: "Camp Count" },
        { key: "bProjection", label: "Blacklisted Projection" },
        { key: "bMetrics", label: "Blacklisted Matrix" },
        { key: "action", label: "Action" },
      ],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      users: [],
      showPassword:{password:false, password1:false},
      usertype_opt: ["subUser","csp"],
      camp_list: [],
      allowedCampigns: [],
      assign_camp: [],
      all_camp_list: [],
      metricsopt: [
        { value: "browse", text: "Click" },
        { value: "render", text: "Render" },
        { value: "impression", text: "Impression" },
        { value: "accepted", text: "Valid" },
        { value: "discarded", text: "Discarded" },
        { value: "rejected", text: "Invalid" },
        { value: "convertedRate", text: "Conversion Rate" },
        { value: "converted", text: "Converted" },
        { value: "harmfulApp", text: "Suspicious Pattern" },
        { value: "iframeTraffic", text: "Iframe Traffic" },
        { value: "proxyTraffic", text: "Proxy Traffic" },
        { value: "requestTempring", text: "Request Tempering" },
        // { value: "deviceAnomalies", text: "Device Anomalies" },
        { value: "dangerIp", text: "Danger IP" },
        { value: "adultKeyword", text: "Adult Keyword" },
        { value: "nonPlayStoreApk", text: "Non PlayStore Application" },
        { value: "susDevConvDist", text: "Suspicious Patterns" },
        { value: "offerrev", text: "Offer Revenue" },
        { value: "camprev", text: "Campaign Revenue" },
        { value: "event", text: "Event Count" },
        { value: "renderRate", text: "Render Rate" },
        { value: "impressionRate", text: "Impression Rate" },
        { value: "rejectedRate", text: "Invalid Rate" },
        { value: "acceptedRate", text: "Valid Rate" },
      ],
      projectionopt: [
        // { value: "campname", text: "Campaign" },
        { value: "offerid", text: "Offer" },
        { value: "tsid", text: "TrafficSource" },
        { value: "country", text: "Country" },
        { value: "browser", text: "Browser" },
        { value: "os", text: "Operating System" },
        { value: "devicetype", text: "Device Type" },
        { value: "networktype", text: "Network Type" },
        { value: "packageid", text: "Package Id"},
        // { value: "frausLvlForUser", text: "Fraud Level" },
        { value: "external_id", text: "External Id" },
        { value: "carrier", text: "Carrier" },
        { value: "languages", text: "Languages" },
        { value: "timezone", text: "TimeZone" },
        { value: "make", text: "Make" },
        { value: "model", text: "Model" },
        { value: "converted", text: "Converted" },
        { value: "eventMessage", text: "Event Message" },
        { value: "qp_sub1", text: "Sub1" },
        { value: "qp_sub2", text: "Sub2" },
        { value: "qp_sub3", text: "Sub3" },
        { value: "qp_sub4", text: "Sub4" },
        { value: "qp_sub5", text: "Sub5" },
        { value: "qp_sub6", text: "Sub6" },
        { value: "qp_sub7", text: "Sub7" },
        { value: "qp_sub8", text: "Sub8" },
        { value: "qp_sub9", text: "Sub9" },
        { value: "qp_sub10", text: "Sub10" },
        { value: "qp_sub11", text: "Sub11" },
        { value: "qp_sub12", text: "Sub12" },
      ],
    };
  },
  computed: {
    validateName(){
      if (this.input.username == "") {
        return false;
      }else if(HelperFunction.validateName(this.input.username,100) == false) {
        this.input.username = ''
        return false;
      }else{
        return true;
      }
    }
  },
  watch:{
    show_model(){
      if(this.show_model == false){
        this.allowedCampigns = []
      }
    }
  },
  async mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true" || window.localStorage.getItem("user") == "subUser"
    ) {
      this.$router.push("/");
    }else{
      this.email = window.localStorage.getItem("email");
      this.role = window.localStorage.getItem("user");
      if (localStorage.getItem("subusers_table") != null)
        this.tableperpage = parseInt(localStorage.getItem("subusers_table"));
      this.getUsers();
      this.getcampList()
    }
  },
  methods: {
    async getReportOptions(){
    let response = await SettingService.getSubValue();
      if(response.result == true){
        let subMapData = response.data.subValueMappping || {};
          Object.keys(subMapData).forEach((key) => {
            this.projectionopt.map(item => {
              if(item.value == 'qp_'+key){
                item.text = subMapData[key];
              }
            })
          });
        }
    },
    addTagBack(tag) {
      // check if not in camp_list then push tag
      if(this.camp_list.filter(t=> t._id == tag._id).length == 0){
        this.camp_list.push(tag);
      }
      this.allowedCampigns = this.allowedCampigns.filter(t=> t._id != tag._id)
    },
   async openModel(data){
      await this.getcampList()
        this.show_model = true;
        this.camp_list = [];
        this.input1 = data;
        this.input1.password = ''
        if(data.role == 'csp'){
           this.allowedCampigns = []
            data.allowedCampigns.forEach(element => {
              if(this.all_camp_list.filter(t=> t._id == element)[0]){
              this.allowedCampigns.push(this.all_camp_list.filter(t=> t._id == element)[0])
              }
            });
            this.isLiveDashboard = data.isLiveDashboard?true:false
            this.isViewLog = data.isViewLog?true:false
            this.isInspector = data.iwitness?true:false
        }
        this.camp_list = this.all_camp_list.filter(t=> this.assign_camp.indexOf(t._id) == -1)
        this.bMatrics = this.metricsopt.filter(t=>data.bMetrics.indexOf(t.value) != -1)
        this.bProjection = this.projectionopt.filter(d=> data.bProjection.indexOf(d.value) != -1)
      },
    CreatePayload(data){
      var payload = data
      if(data.role == 'csp'){
        payload.allowedCampigns = this.allowedCampigns.map(t=>t._id)
      }
      return payload
    },
    async signup() {
      if (this.validate()) {
          let response;
          let payload = this.CreatePayload(this.input)
          response = await SignUpService.addSubUser(payload);
          if (response.result == true) {
            Swal.fire({
              title: "Success!",
              text: response.message,
              icon: "success",
              confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
            });
            this.getUsers();
            this.input = {username:'',role:'subUser',allowedCampigns:[],password:'',subUserEmail:''};

          } else {
            Swal.fire({
              title: "Error!",
              text: response.message,
              icon: "error",
              confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
            });
          }
        }
    },
    async Edit() {
      let response;
      try {
        if (this.change_password == true && (this.input1.password == "" || this.input1.password.length < 6)) {
          Swal.fire({
            title: "Error!",
            text: "Please use minimum 6 digit password",
            icon: "error",
            confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          return
        }
        let bMatrics = this.bMatrics.map(t=>t.value)
        let bProjection = this.bProjection.map(t=>t.value)
          let postdata = {
            subUserEmail: this.input1.email,
            changeBody:{
              bMetrics: bMatrics,
              bProjection: bProjection
            }
          };
          if(this.change_password == true){
            postdata.changeBody.password = this.input1.password
          }
          if(this.input1.role == 'csp'){
            postdata.changeBody.allowedCampigns = this.allowedCampigns.map(t=>t._id)
            postdata.changeBody.isLiveDashboard = this.isLiveDashboard
            postdata.changeBody.isViewLog = this.isViewLog
            postdata.changeBody.iwitness = this.isInspector
          }
          response = await SignUpService.editSubuser(postdata); 
          if (response.result) {
              Swal.fire({
                title: "Success!",
                text: response.message,
                icon: "success",
                confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
              });
              this.show_model = false
              this.allowedCampigns = []
              this.input1 = {}
              this.getUsers();
            }
            else{
              Swal.fire({
                title: "Error!",
                text: response.message,
                icon: "error",
                confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
              });
            }
      } catch (e) {
        console.log(e);
      }
    },
    validate() {
      if (HelperFunction.validateemail(this.input.subUserEmail)) {
        if(this.input.username == "" || this.input.password == "" || this.input.role == null){
          Swal.fire({
            title: "Error!",
            text: "Please Enter all details",
            icon: "error",
            confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          return false;
        }
        else if (this.input.password.length < 6 && this.input.password == "") {
          Swal.fire({
            title: "Error!",
            text: "Please use minimum 6 digit password",
            icon: "error",
            confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          return false;
        }else {
          return true;
        }
      } else {
        Swal.fire({
          title: "Error!",
          text: "Please Enter valid email",
          icon: "error",
          confirmButtonText: "Close",position: 'top-end',showConfirmButton: false,timer: 1000
        });
      }
    },
    checkPage() {
      localStorage.setItem("subusers_table", this.tableperpage);
    },
    async getUsers() {
      await this.getReportOptions();
      let response;
      try {
        response = await SignUpService.getSubusers();
        this.users = response.message;
        this.assign_camp = [];
        this.users.map((t)=> {
          if(t.role == 'csp'){
            for(var i=0;i<t.allowedCampigns.length;i++){
              this.assign_camp.push(t.allowedCampigns[i])
            }
          }
        })
        // this.users = response.message.filter(t=>{
        // if(t.role != 'manager')
        // return t
        // })
        this.items = this.users;
        this.totalRows = this.items.length;
      } catch (e) {
        console.error("Error in login");
        console.log(e);
      }
    },
    async update(formData) {
      let response;
      try {
        if (formData.isactive == true) {
          formData.isactive = false;
        } else {
          formData.isactive = true;
        }
        let payload = {
          subUserEmail: formData.email,
          isactive: formData.isactive,
        };
        response = await SignUpService.changeStatus(payload);
        if (response.result == true) {
          Swal.fire({
            title: "Success!",
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
          });
          this.getUsers();
        } else {
          Swal.fire({
            title: "Error!",
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",position: 'top-end',showConfirmButton: false,timer: 1000
          });
        }
      } catch (e) {
        console.error("Error in login");
        console.log(e);
      }
    },
    async getcampList() {
      this.all_camp_list = [];
      var res = await DashboardController.camplist();
      var camp = res.message.campaign;
      Object.keys(camp).map((t) => {
        this.all_camp_list.push({
          _id: camp[t],
          name: t,
        });
      });
    },
    togglePassword(id){
          const password = document.getElementById(id);
          this.showPassword[id] = this.showPassword[id] === false ? true: false
          const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
          password.setAttribute('type', type);
        }
  },
};
</script>

<style>
.b_card {
  background-color: white;
  /* box-shadow: rgb(136, 136, 136) 2px 5px 7px 2px; */
}

.bg_page {
  background: white;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;
  /*position: fixed;*/
  /*background: rgb(2, 0, 36);*/
  /*background: linear-gradient(152deg, rgba(2, 0, 36, 1) 0%, rgba(15, 15, 126, 1) 49%, rgba(0, 212, 255, 1) 100%);*/
}
.logout:hover {
  background-color: #b82e2b;
}
.v_tag {
  overflow-y: scroll;
  max-height: 100px;
}
.link {
  color: blue;
  cursor: pointer;
}
</style>
