import api from './api'

export default {
    signup(data){
        return api.execute(`post`,`/signup`,data)
    },
    addSubUser(data){
        return api.execute(`post`,`/subuser/create`,data)
    },
    getSubusers(){
        return api.execute(`get`,`/subuser/getall`)
    },
    changeStatus(data){
        return api.execute(`post`,`/subuser/changestatus`,data)
    },
    editSubuserOld(data){
        return api.execute(`post`,`/resetsubpwd`,data)
    },
    editSubuser(data){
        return api.execute(`put`,`/subuser/edit`,data) 
    }
}
